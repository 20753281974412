import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    timeout: {
      type: Number,
      default: 5000,
    },
  };

  connect() {
    this.element.style.opacity = 1;
    this.element.style.transition = `opacity 1s`;
    setTimeout(() => {
      this.element.style.opacity = 0;
      setTimeout(() => this.element.remove(), 1000);
    }, this.timeoutValue);
  }
}
