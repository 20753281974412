import { Controller } from '@hotwired/stimulus';
import ScrollMagic from 'scrollmagic';
import { Linear, TweenMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default class extends Controller {
  static targets = ['trigger', 'scope'];
  static values = {
    height: String,
  };

  connect() {
    var controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 'onEnter',
        duration: '200%',
      },
    });
    this.triggerTarget.style.height = this.heightValue;
    this.triggerTarget.style.overflow = 'hidden';

    this.scopeTarget.style.position = 'relative';

    new ScrollMagic.Scene({
      triggerElement: this.triggerTarget,
    })
      .setTween(this.scopeTarget, {
        y: '100%',
        ease: Linear.easeNone,
      })
      .addTo(controller);
  }
}
