// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import Sortable from 'sortablejs';

export default class extends Controller {
  static values = { handle: String, url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.onEnd.bind(this),
      handle: this.handleValue,
    });
  }

  onEnd(event) {
    let id = event.item.dataset.id;
    let data = new FormData();
    data.append('position', event.newIndex + 1);

    Rails.ajax({
      url: this.urlValue.replace(':id', id),
      type: 'PATCH',
      data: data,
    });
  }
}
