import { Controller } from '@hotwired/stimulus';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const plugin = {
  id: 'custom_center_text',
  beforeDraw: (chart) => {
    var width = chart.width,
      height = chart.height,
      ctx = chart.canvas.getContext('2d');
    ctx.restore();
    var fontSize = (height / 150).toFixed(2);
    ctx.font = fontSize + "em 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
    ctx.fillStyle = '#aa896d';
    ctx.textBaseline = 'middle';
    var text = chart.config.options.elements.center.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2 - 15;
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

export default class extends Controller {
  static targets = ['canvas'];
  static values = {
    data: Object,
    options: Object,
    url: String,
  };

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element;

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue || 'doughnut',
      data: this.chartData,
      options: {
        ...this.defaultOptions,
        ...this.chartOptions,
      },
      plugins: [plugin],
    });
    this.refresh();
  }

  disconnect() {
    this.chart.destroy();
    this.chart = undefined;
  }

  async refresh() {
    if (this.hasUrlValue) {
      const response = await fetch(this.urlValue);
      const data = await response.json();

      this.chart.data = data;
      this.chart.update();
    }
  }

  get chartData() {
    return this.hasDataValue ? this.dataValue : {};
  }

  get chartOptions() {
    return this.hasOptionsValue ? this.optionsValue : {};
  }

  get defaultOptions() {
    return {
      datasets: {
        doughnut: {
          cutout: '80%',
        },
      },
    };
  }
}
