// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['option'];

  initialize() {
    this.filterElements();
  }

  filterElements() {
    switch (this.contentType) {
      case 'banner':
        document.getElementById('header-group').classList.remove('d-none');
        document.getElementById('caption-group').classList.remove('d-none');
        document.getElementById('image-group').classList.remove('d-none');
        document.getElementById('button-group').classList.remove('d-none');
        document.getElementById('text-group').classList.add('d-none');
        document.getElementById('blockquote-group').classList.add('d-none');
        break;
      case 'image':
        document.getElementById('header-group').classList.add('d-none');
        document.getElementById('caption-group').classList.add('d-none');
        document.getElementById('image-group').classList.remove('d-none');
        document.getElementById('button-group').classList.add('d-none');
        document.getElementById('text-group').classList.add('d-none');
        document.getElementById('blockquote-group').classList.add('d-none');
        break;
      case 'blockquote':
        document.getElementById('header-group').classList.add('d-none');
        document.getElementById('caption-group').classList.add('d-none');
        document.getElementById('image-group').classList.add('d-none');
        document.getElementById('button-group').classList.add('d-none');
        document.getElementById('text-group').classList.add('d-none');
        document.getElementById('blockquote-group').classList.remove('d-none');
        break;
      case 'rich_card':
        document.getElementById('header-group').classList.remove('d-none');
        document.getElementById('caption-group').classList.remove('d-none');
        document.getElementById('image-group').classList.remove('d-none');
        document.getElementById('button-group').classList.remove('d-none');
        document.getElementById('text-group').classList.add('d-none');
        document.getElementById('blockquote-group').classList.add('d-none');
        break;
      case 'rich_text':
        document.getElementById('header-group').classList.add('d-none');
        document.getElementById('caption-group').classList.add('d-none');
        document.getElementById('image-group').classList.add('d-none');
        document.getElementById('button-group').classList.add('d-none');
        document.getElementById('text-group').classList.remove('d-none');
        document.getElementById('blockquote-group').classList.add('d-none');
        break;
      default:
        document.getElementById('header-group').classList.remove('d-none');
        document.getElementById('caption-group').classList.remove('d-none');
        document.getElementById('image-group').classList.remove('d-none');
        document.getElementById('button-group').classList.remove('d-none');
        document.getElementById('text-group').classList.remove('d-none');
        document.getElementById('blockquote-group').classList.add('d-none');
    }
  }

  onSelect(event) {
    this.contentType = event.target.value;
  }

  get contentType() {
    return this.data.get('type');
  }

  set contentType(value) {
    this.data.set('type', value);
    this.filterElements();
  }
}
