import { Controller } from '@hotwired/stimulus';
const ScrollMagic = require('scrollmagic');

export default class extends Controller {
  static targets = ['trigger', 'reveal'];
  connect() {
    var controller = new ScrollMagic.Controller();
    new ScrollMagic.Scene({
      triggerElement: this.triggerTarget,
      triggerHook: 0.9, // show, when scrolled 10% into view
      //duration: "90%", // hide 10% before exiting view (90% + 10% from bottom)
      offset: 50, // move trigger to center of element
    })
      .setClassToggle(this.revealTarget, 'visible') // add class to reveal
      .addTo(controller);
  }
}
