import { Controller } from '@hotwired/stimulus';
import Swiper, { Autoplay, Pagination } from 'swiper';

export default class extends Controller {
  static values = {
    options: Object,
  };

  connect() {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      modules: [Autoplay, Pagination],
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        992: {
          slidesPerView: 'auto',
          spaceBetween: 30,
        },
      },
    };
  }
}
