/*global $*/

import './jquery'

// Entry point for the build script in your package.json
import '@popperjs/core';
import './bootstrap_custom.js';
import '@fortawesome/fontawesome-free/js/all';

import "@hotwired/turbo-rails"
require('@rails/activestorage').start();
import "./controllers"
import './public/src/analytics';
//import * as bootstrap from "bootstrap"

import ScrollMagic from 'scrollmagic';

document.addEventListener('turbo:load', function () {
  var controller = new ScrollMagic.Controller();
  $('#technical-features-container').each(function () {
    var el = this;
    var wrapper = $(el).find('.technical-features-wrapper')[0];
    var entries = $(el).find('.technical-feature-section');
    var duration = 800;
    $(el).append('<a class="trigger trigger-0" data-index="0"></a>');
    for (var i = 0; i < $(entries).length; i++) {
      $(el).append('<a class="trigger trigger-' + (i + 1) + '" data-index="' + (i + 1) + '"></a>');
    }
    var trigger = $(el).find('.trigger');
    var triggerCount = $(trigger).length;
    $(trigger).each(function (index) {
      var t = this;
      var current_entry = null;
      var offset = -500;

      if (index == 0) {
        new ScrollMagic.Scene({
          triggerElement: t,
          duration: $(entries).length * duration + offset,
        })
          .setPin(wrapper)
          //.addIndicators()
          .addTo(controller);
      } else if (index == triggerCount - 1) {
        current_entry = $(entries).eq(index - 1)[0];
        // var main_entry = $(current_entry).parents('.entry');
        //var first_radial = $(main_entry).find('.sub-entry .radial-progress').eq(0);
        new ScrollMagic.Scene({ triggerElement: t, offset: offset }).setClassToggle(current_entry, 'active-entry').addTo(controller);
      } else {
        current_entry = $(entries).eq(index - 1)[0];
        //var main_entry = $(current_entry).parents('.entry');
        //var first_radial = $(main_entry).find('.sub-entry .radial-progress').eq(0);
        new ScrollMagic.Scene({
          triggerElement: t,
          duration: duration,
          offset: offset,
        })
          .setClassToggle(current_entry, 'active-entry')
          .addTo(controller);
      }
    });
  });
});
