import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    offset: {
      type: Number,
      default: 10,
    },
    behavior: {
      type: String,
      default: 'smooth',
    },
  };

  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.element.addEventListener('click', this.scroll);

    this.offset = this.offsetValue;
    this.behavior = this.behaviorValue;
  }

  disconnect() {
    this.element.removeEventListener('click', this.scroll);
  }

  scroll(event) {
    event.preventDefault();

    const id = this.element.hash.replace(/^#/, '');
    const target = document.getElementById(id);

    if (!target) {
      console.warn(`[scroll-to] The element with the id: "${id}" does not exist on the page.`);
      return;
    }

    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - this.offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior,
    });
  }
}
